import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import categorized_wines from '../../data/wines.json'
import constants from '../../constants';

const BorainkPage = () => {
  
  return (<Layout page="boraink">
    <article id="main">
      <header>
        <h2>Boraink</h2>
        <p>A legmegfelelőbb termőhely kiválasztásával, a visszafogott terméssel és a legkorszerűbb feldolgozással boraink állandó jó minőségét garantáljuk. Munkánkat igazolja a hazai versenyeken elért aranyérmek és díjak.</p>
      </header>
    </article>

    <section id="two" className="wrapper alt style2">
      <div>
        <ul className="features bottom-chin">
          {
            categorized_wines.map(cat => (
              <li className="boraink-list">
                <Link className="boraink-link" to={`/hu/boraink#${cat.category}`}>
                  <h5>{cat.category}</h5>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>
      {
        categorized_wines
          // concatenate all the wines (flattening the array of arrays)
          .reduce((acc, val) => acc.concat(val.wines), [])
          .map((wine, index) => (
            <>
              {
                wine.id &&
                  <section id={wine.id} style={{padding: '1.5rem' }} className="wrapper style1 special">
                    <div className="inner">
                      <header style={{ margin: 0 }} className="major" >
                        <h4 style={{ margin: 0, padding: 0, border: 'none' }}>
                          {
                            categorized_wines
                              .find(category =>
                                category.wines.find(w => w === wine))
                              .category
                              .toUpperCase()
                          }
                        </h4>
                      </header>
                    </div>
                  </section>
              }
              <section className="spotlight non-alternating" style={{ flexDirection: index % 2 === 0 ? `row-reverse`: `row` }}>
                <div className={`shifted-image ${index % 2 === 0 ? ``: `reverse`}`}>
                  <img src={wine.image} alt={wine.name} />
                </div>
                <div className="content">
                  <h2>{wine.name}</h2>
                  <p>{wine.description}</p>
                  <a href={wine.pdf} target="__blank" style={{ margin: "8px" }} className="button primary">
                    Letölthető Adatlap
                  </a>
                  <a href={constants.WEBSHOP_LINK} style={{ margin: "8px" }} target="__blank" className="button primary">
                    Ugrás a Webshopra
                  </a>
                </div>
              </section>
            </>
          )
        )
      }
    </section>
  </Layout>
  )
  };

export default BorainkPage;
